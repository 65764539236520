<template>
    <div class="dynamo-page">
        <div class="title">
            定制代拿
        </div>
        <div class="search-div">
            <el-button type="primary" size="small" @click="jumpToAddDynamo()">添加定制代拿</el-button>
            <div style="display: flex;">
               <el-input size='medium' v-model="querySelect" style="margin-right: 10px;"></el-input>
               <el-button type="primary" size="small" @click="searchList">搜索</el-button>
            </div>
        </div>

        <div class="table-container">
            <el-table border :data="dynamoList">
                <el-table-column prop="title" align="center" label="标题"  width="180">
                </el-table-column>
                <el-table-column prop="subtitle" align="center" label="副标题" width="180">
                </el-table-column>
                <el-table-column prop="price" align="center" label="价格（元）" width="180">
                     <template slot-scope="scope">
                        {{(scope.row.price/100).toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="创建时间">
                    <template slot-scope="scope">
                        {{scope.row.create_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="更新时间">
                    <template slot-scope="scope">
                        {{scope.row.update_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column prop="update_user_name" align="center" label="最后更新者">
                    <template slot-scope="scope">
                        {{scope.row.update_user_name || scope.row.create_user_name}}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <span>
                         <el-button type="text" size="small" style="margin-right: 10px" @click="jumpToAddDynamo(scope.row)">编辑</el-button>
                         </span>
                         <span>
                             <el-popconfirm
                                confirmButtonText='确定'
                                cancelButtonText='不用了'
                                icon="el-icon-info"
                                iconColor="red"
                                @onConfirm="deleteDynamo(scope.row.id)"
                                title="确定删除该定制快递吗？">
                                <el-button slot="reference" type="text" size="small">删除</el-button>
                            </el-popconfirm>
                         </span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="page-pageination" background layout="prev, pager, next" :total="total" :page-size="size"
            :current-page.sync="page" @current-change="getDynamoList">
            </el-pagination>
        </div>
    </div>
</template>
<script>

export default {
    name: 'Dynamo',
    data () {
        return {
            // 定制快递列表
            dynamoList: [],
            page: 1,
            size: 10,
            total: 0,

            querySelect: '',
            visible: false
        }
    },
    mounted () {
        this.getDynamoList()
    },
    methods: {
        // 获取定制快递列表
        getDynamoList () {
            this.axios.post('/dynamo/get/list', {
                page: this.page,
                size: this.size,
                query_select: this.querySelect
            }).then((res) => {
                this.dynamoList = res.data[0].list
                this.total = res.data[0].count
                console.log(res)
            })
        },

        searchList () {
            this.page = 1
            this.getDynamoList()
        },

        // 跳转到编辑或者新增界面
        jumpToAddDynamo (editDynamoData) {
            if (editDynamoData) {
                localStorage.setItem('dynamoEditData', JSON.stringify(editDynamoData))
            }
            this.$router.push('dynamo/add/' + !!editDynamoData)
        },

        // 删除
        deleteDynamo (ediDynamoId) {
            this.axios.post('/dynamo/delete', {
                dynamo_id: ediDynamoId
            }).then((res) => {
                if (res) {
                    this.getDynamoList()
                    this.$message.success('删除成功')
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .dynamo-page{
        .title{
            padding: 14px;
            margin-bottom: 18px;
            border-bottom: 1px solid #dddddd;
            font-size: 28px;
        }

        .search-div{
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            border-bottom: 1px solid #dddddd;
        }

        .table-container{
            margin: 10px 20px 0;
        }

        .page-pageination{
            padding: 10px 0;
            text-align: right;
        }
    }
</style>
