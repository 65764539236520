<template>
    <div class="add-dynamo-page">
        <div class="title">
            <span>{{this.$route.params.isEdit === 'true' ? '添加' : '编辑'}}定制代拿</span>
        </div>
        <div>
            <el-form :model="addDynamoData" ref="addDynamoForm" status-icon :rules="rules" label-width="86px">
                <el-form-item label="标题" prop="title">
                    <el-input v-model="addDynamoData.title" size="medium" ></el-input>
                </el-form-item>
                <el-form-item label="副标题">
                    <el-input v-model="addDynamoData.subtitle" size="medium" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="图片" style="margin-bottom: 10px;">
                    <el-upload
                        class="avatar-uploader"
                        action="/api/upload/file"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="addDynamoData.images" :src="addDynamoData.images" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="详情">
                    <wangEditor v-model="addDynamoData.details" style="width: 600px;"></wangEditor>
                </el-form-item>
                <el-form-item label="价格">
                    <el-input-number v-model="addDynamoData.price" :min="0" :max="1000000" size="medium" autocomplete="off"></el-input-number>
                    <div class="note">单位为分，1元为100分</div>
                </el-form-item>
            </el-form>
            <div style="padding: 14px 80px;">
                  <el-button type="primary" size="small" @click="submitDynamo('addDynamoForm')"> 保 存 </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import wangEditor from '@/components/wangEditor.vue'
export default {
    name: 'AddDynamo',
    components: { wangEditor },
    data () {
        return {
            // 验证规则
            rules: {
                title: [
                    { required: true, message: '请输入标题', trigger: 'change' }
                ]
            },

            // 增加/编辑快递业务数据
            addDynamoData: {
                title: '',
                subtitle: '',
                images: '',
                details: '',
                price: ''
            }
        }
    },
    mounted () {
        console.log(JSON.parse(localStorage.getItem('dynamoEditData')))
        // 取参为字符串
        if (this.$route.params.isEdit === 'true') {
            // eslint-disable-next-line camelcase
            const { title, subtitle, images, details, price, id } = { ...JSON.parse(localStorage.getItem('dynamoEditData')) }

            this.addDynamoData = { title, subtitle, images, details, price }
            // eslint-disable-next-line camelcase
            this.addDynamoData.dynamo_id = id
            console.log(this.addDynamoData)
        }
    },
    methods: {
        // 上传图片成功回调
        handleAvatarSuccess (res, file) {
            this.addDynamoData.images = res.data[0].url
        },
        // 图片上传前验证，图片格式、大小等
        beforeAvatarUpload (file) {
            console.log(file.type)
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
            const isLt2M = file.size / 1024 / 1024 < 2

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG/PNG/GIF 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!')
            }
            return isJPG && isLt2M
        },

        submitDynamo (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const requestUrl = this.$route.params.isEdit === 'true' ? '/dynamo/update' : '/dynamo/add'

                    this.axios.post(requestUrl, this.addDynamoData).then((res) => {
                        this.$router.push('/home/dynamo')
                    }).catch(() => {
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .add-dynamo-page{
        .title{
            padding: 14px;
            margin-bottom: 18px;
            border-bottom: 1px solid #dddddd;
            font-size: 28px;
        }

        .el-input, .el-input-number{
            width: 260px;
        }

        .note{
            line-height: 20px;
            font-size: 14px;
            color: #d84040;
        }

        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 160px;
            height: 160px;
            line-height: 160px;
            text-align: center;
        }
        .avatar {
            width: 160px;
            height: 160px;
            display: block;
        }
    }
</style>
